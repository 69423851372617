<template>
    <div class="dashboard-actions-box actions-box">
        <button class="btn w100p button-space-x" @click="onConnectWalletClick">
            <icon data="@/assets/svg/connect.svg" width="16" height="16" aria-hidden="true" />
            Connect Wallet
        </button>
        <button class="btn w100p button-space-x" @click="onCreateAWalletClick">
            <icon data="@/assets/svg/wallet.svg" width="16" height="16" aria-hidden="true" />
            Create Wallet
        </button>
        <button class="btn w100p button-space-x" @click="onRestoreWalletClick">
            <icon data="@/assets/svg/restore.svg" width="16" height="16" aria-hidden="true" />
            Restore Wallet
        </button>

        <create-account-window ref="createAccountWindow" />
        <restore-account-window ref="restoreAccountWindow" />
        <connect-wallet-window ref="connectWalletWindow" />
    </div>
</template>

<script>
import CreateAccountWindow from '../windows/CreateAccountWindow/CreateAccountWindow.vue';
import RestoreAccountWindow from '../windows/RestoreAccountWindow/RestoreAccountWindow.vue';
import ConnectWalletWindow from '@/components/windows/ConnectWalletWindow/ConnectWalletWindow.vue';

export default {
    name: 'DashboardActionsBox',

    components: { ConnectWalletWindow, RestoreAccountWindow, CreateAccountWindow },

    methods: {
        onCreateAWalletClick() {
            this.$refs.createAccountWindow.show();
        },

        onRestoreWalletClick() {
            this.$refs.restoreAccountWindow.show();
        },

        onConnectWalletClick() {
            this.$refs.connectWalletWindow.show();
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
