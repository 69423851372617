<template>
    <ul class="f-social-media-links no-markers">
        <li>
            <a href="https://twitter.com/nextsmartchain" target="_blank" rel="noopener" title="NEXT on Twitter">
                <icon data="@/assets/svg/social-media/twitter.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://www.reddit.com/r/nextsmartchain/" target="_blank" rel="noopener" title="NEXT on Reddit">
                <icon data="@/assets/svg/social-media/reddit.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://www.linkedin.com/company/nextsmartchain/"
                target="_blank"
                rel="noopener"
                title="NEXT on Linkedin"
                class="linkedin"
            >
                <icon data="@/assets/svg/social-media/linkedin.svg" width="24" height="24"></icon>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'SocialMediaLinks',
};
</script>

<style lang="scss">
@import 'style';
</style>
