var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collateral-positions-list-dt"},[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"action-on-row":_vm.actionOnRow,"first-m-v-column-width":"6","f-card-off":"","caption":"Collateral Positions"},scopedSlots:_vm._u([{key:"column-asset",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-crypto-symbol',{attrs:{"token":item}})],1)]):[_c('f-crypto-symbol',{attrs:{"token":item}})]]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":_vm.formatCollateral(item),"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":_vm.formatCollateral(item),"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-amount_fusd",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":_vm.formatCollateralFUSD(item),"token":{ symbol: 'FUSD' },"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":_vm.formatCollateralFUSD(item),"token":{ symbol: 'FUSD' },"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-actions",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('router-link',{attrs:{"to":{ name: 'defi-lock', params: { tokenAddress: item.address } }}},[_vm._v(" Lock ")]),_vm._v(" , "),_c('router-link',{attrs:{"to":{ name: 'defi-unlock', params: { tokenAddress: item.address } }}},[_vm._v(" Unlock ")]),(item.symbol === 'WNEXT')?[_vm._v(" , "),_c('router-link',{attrs:{"to":{ name: 'defi-ftrade' }}},[_vm._v("Swap")])]:_vm._e()],2)]):[_c('router-link',{attrs:{"to":{ name: 'defi-lock', params: { tokenAddress: item.address } }}},[_vm._v(" Lock ")]),_c('br'),_c('router-link',{attrs:{"to":{ name: 'defi-unlock', params: { tokenAddress: item.address } }}},[_vm._v(" Unlock ")]),(item.symbol === 'WNEXT')?[_c('br'),_c('router-link',{attrs:{"to":{ name: 'defi-ftrade' }}},[_vm._v("Swap")])]:_vm._e()]]}}])}),_c('deposit-or-borrow-token-window',{ref:"win",attrs:{"token":_vm.dbToken,"deposit-route-name":_vm.depositRouteName,"borrow-route-name":_vm.borrowRouteName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }